import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DocumentoFD } from 'src/app/core/models/documentoFD';
import Swal from 'sweetalert2';
import { Parametricas } from '../../../core/enums/parametricas.enums'
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component';
import { AdsibUtilService } from 'src/app/core/services/adsib-util.service';
import { TramiteService } from 'src/app/core/services/tramite.service';
import { lastValueFrom } from "rxjs";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";
@Component({
  selector: 'app-formulario-observacion',
  templateUrl: './formulario-observacion.component.html',
  styleUrls: ['./formulario-observacion.component.scss']
})
export class FormularioObservacionComponent implements OnInit {
  public observacionForm: FormGroup;
  idTramite: number
  idEstado: any
  tramite: any
  sustancia: any
  usuario: any
  tituloC = Parametricas.TITULO_OBSERVACION
  subtituloC = Parametricas.subtitulo_observacion
  constructor(private usuarioService: UsuarioService,
    public dialog: MatDialog,
    public dialogObservacion: MatDialogRef<FormularioObservacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private adsibUtilService: AdsibUtilService,
    private tramiteService: TramiteService,
  ) {
    this.observacionForm = this.formBuilder.group({
      motivo_observacion: ['',Validators.maxLength(2500)]
    })
    this.idTramite = data.id_tramite
    // this.idEstado = data.idEstado

  }
  ngOnInit(): void {
    this.usuario = this.usuarioService.usuario
    this.dialogObservacion.updateSize('700px', 'auto')
    this.obtenerDatosTramite()
  }

  async obtenerDatosTramite() {
    try {
      var respDatosTramite = await lastValueFrom(this.tramiteService.obtenerDatosTramite({ idTramite: this.idTramite }))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    this.tramite = respDatosTramite?.datos.datosTramite
    this.sustancia = respDatosTramite?.datos.datosSustancia
  }

  async GuardarObservacion() {
    var respuesta = await Swal.fire({
      title: "¿Desea guardar el registro?", text: "¡No podrás revertir esto! ", icon: "question",
      showCancelButton: true, confirmButtonText: "Si, guardar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })

    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      html: "¡Almacenando Registro!",
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const params = {
      id_tramite: this.data.id_tramite,
      observacion: this.observacionForm.value.motivo_observacion
    }
    
    this.dialogObservacion.close({ res: true, code: 1, params: params });
    return

  }
  cancelar() {
    this.dialogObservacion.close({ res: false, code: 0 });
  }
  
}