import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { DocumentoFD } from '../models/documentoFD';
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component';
import { BaseHttpService } from './base-http.service';
import { environment } from "../../../environments/environment";
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
const base_url_sistema = environment.base_url_sistema;
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
import { StatusColorPipe } from '../../shared/pipes/status-color.pipe';

@Injectable({
  providedIn: 'root'
})
export class TramiteService {
  listItems: any[] = [];
  titulo: any
  subtitulo: any
  constructor(
    private baseHttpService: BaseHttpService,
    private http: HttpClient,
    public pdfModalDialog: MatDialog,
    /**
 * Constructor  que permite consumir los servicios de usuarios
 */
    public _usuarioService: UsuarioService,
  ) { }

  getFechaSistema(): Observable<any> {
    return this.http.get(`${base_url_sistema}/fecha`);
  }

  getDocPDF(uuid: string): Observable<any> {
    return this.http.get(`${base_url_sistema}/pdf/${uuid}`);
  }

  async carpetaDigital(idTramite: string) {
    // console.log('GET TWO CARP DIG');
    const parametros = { "idTramite": idTramite }
    // return this.baseHttpService.post('tramite/carpeta-digital', parametros);

    Swal.fire({ title: '', text: 'Cargando espere por favor!', icon: 'info', allowOutsideClick: false });
    Swal.showLoading();
    try {
      var res = await this.baseHttpService.post('tramite/carpeta-digital', parametros).toPromise();
      console.log(res);

    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }

    if (!res.datos.pdf64) Swal.fire("Error", "No tenemos el archivo para mostrarlo", "info");
    Swal.close();
    // Visualizar el pdf generado
    const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
      disableClose: true,

      data: {
        archivoBase64: res.datos.pdf64,
        titulo: "Carpeta Digital",
        textoBoton: "Aceptar"
      },
      autoFocus: false
    });

  }

  getCarpetaDigital(idTramite: string): Observable<any> {
    const parametros = { "idTramite": idTramite }
    return this.baseHttpService.post('tramite/carpeta-digital', parametros);
  }
  /**
   * Funcion de asignacion tramite
    */
  async tramiteAsigandoDetalle(fila: any, solicitud: any) {
    console.log("entro detalle", fila, solicitud);

    //console.log("fila",fila)
    //console.log("solicitud",solicitud)
    const tipoTramite = fila?.id_tipo_tramite
    const titulo = Parametricas.headerTitle;
    const subtitulo = Parametricas.headerSubtitle;
    let subtipoTramite = ''
    console.log("subtipo Tramite::", fila.id_subtipo_tramite);
    if (tipoTramite == 12) {
      const subtipoTramites = {
        19: 'ACTUALIZACIÓN DE INFORMACIÓN POR CATEGORIZACIÓN',
        20: 'ACTUALIZACIÓN NUEVOS REQUISITOS 4911',
        21: 'PRIMERA RENOVACIÓN ED6',
        22: 'PRIMERA REHABILITACIÓN ED6'
      }
      subtipoTramite = subtipoTramites[fila?.id_subtipo_tramite] ? subtipoTramites[fila?.id_subtipo_tramite] : 'Nuevo registro'
    } else if (tipoTramite == 17) {
      const subtipoTramites = {
        1: 'INCLUSIÓN DE SUSTANCIA',
        2: 'EXCLUSIÓN DE SUSTANCIA',
        3: 'CAMBIO DE ACTIVIDADES',
        4: 'ACTUALIZACIÓN DE CANTIDADES',
        5 : 'APERTURA DE SUCURSAL LOCAL',
        6: 'BAJA DE SUCURSAL LOCAL',
        7: 'ACTUALIZACIÓN DE SUCURSAL LOCAL',
        8: 'INCLUSIÓN DE REPRESENTANTE LEGAL',
        9 : 'BAJA DE REPRESENTANTE LEGAL',
        10: 'CAMBIO DE REPRESENTANTE LEGAL',
        11: 'ACTUALIZACIÓN DE REPRESENTANTE LEGAL' 
      }
      subtipoTramite = subtipoTramites[fila?.id_subtipo_tramite] ? subtipoTramites[fila?.id_subtipo_tramite] : 'Nuevo registro'
    } else if (tipoTramite == 15) {
      subtipoTramite = 'Renovación de registro '
    }
    const listItems = [
      { label: 'Nro. Trámite', value: fila?.numero_tramite, condition: true },
      { label: 'Tipo de Autorización Previa', value: solicitud?.nombre_autorizacion, condition: this.shouldDisplayItem(solicitud?.nombre_autorizacion) },
      { label: 'Tipo de Trámite', value: fila?.nombre_tipo_tramite, condition: true },
      { label: 'Tipo de Registro', value: subtipoTramite, condition: true },
      { label: 'Número de Registro Anterior', value: solicitud?.numero_registro_anterior ? solicitud?.numero_registro_anterior : '', condition: true },
      { label: 'Proveedor o Cliente', value: solicitud?.comprador, condition: this.shouldDisplayItem(solicitud?.comprador) },
      { label: 'Número de Registro', value: fila?.registro_numero, condition: this.shouldDisplayItem(fila?.registro_numero) },
      { label: 'Estado del Trámite', value: fila?.nombre_estado, color: 'rgb(0 0 0)', 'background-color': '#74e1d8de', condition: true },
      { label: 'Solicitante', value: fila?.solicitante.toUpperCase(), condition: this.shouldDisplayItem(solicitud?.solicitante) },
      { label: 'Sustancia', value: solicitud?.nombre_sustancia, condition: this.shouldDisplayItem(solicitud?.nombre_sustancia) },
      { label: 'Sinónimo o Producto Terminado', value: solicitud?.nombre_primaria, condition: this.shouldDisplayItem(solicitud?.nombre_primaria) },

      {
        label: 'Días de Vigencia',
        value: solicitud?.dias_vigencia ? `${solicitud.dias_vigencia} días` : 'No especificado',
        condition: this.shouldDisplayItem(solicitud?.dias_vigencia !== null && solicitud?.dias_vigencia !== undefined && solicitud?.dias_vigencia !== 0),
      },
      { label: 'Fecha Trámite', value: fila?.fecha_alta, condition: true },
      { label: 'Fecha de Asignación', value: solicitud?.fecha_asignacion, condition: this.shouldDisplayItem(solicitud?.fecha_asignacion) },
      {
        label: 'Plazo de Atención',
        value: solicitud?.fecha_fin_plazo,
        color: 'rgb(0 0 0)',
        'background-color': 'rgb(241 165 165)',
        condition: this.shouldDisplayItem(solicitud?.fecha_fin_plazo != null && solicitud?.id_estado !== 405),
        // Agregar "(fuera del radio urbano)" al valor si radio_urbano es mayor que 0
        ...(solicitud?.radio_urbano > 0 && solicitud?.asignacion_rol === 9 && solicitud?.id_estado === 629 ? { value: `${solicitud.fecha_fin_plazo} (Fuera del Radio Urbano)` } : {})
      },
      { label: 'Plazo de Atención Observado', value: solicitud?.fecha_fin_plazo_observacion, condition: this.shouldDisplayItem(solicitud?.id_estado === 405 && solicitud?.fecha_fin_plazo_observacion) },
      {
        label: 'Tipo de Pago',
        value: 'PASARELA DE PAGO ONLINE',
        condition: this.shouldDisplayItem(solicitud?.tipo_pago === 2),
        imageSrc: 'assets/images/red_enlace.png'
      },
      { label: 'Tipo de Pago', value: 'COMPROBANTE DEL DEPÓSITO O TRANSFERENCIA BANCARIA', condition: this.shouldDisplayItem(solicitud?.tipo_pago === 1) },
      { label: 'Observaciones para el trámite', value: '"Un Nuevo Registro" -> ' + solicitud?.descripcion_tipo_registro, condition: this.shouldDisplayItem(solicitud?.tipo_registro === 1) },
      { label: 'Observaciones para el trámite', value: '"Tiene un Registro" -> ' + solicitud?.descripcion_tipo_registro, condition: this.shouldDisplayItem(solicitud?.tipo_registro === 2) },
    ];

    return { listItems: listItems, titulo: titulo, subtitulo: subtitulo };
  }
  /**
   * Funcion muestra segun la condicion recibida
    */
  shouldDisplayItem(condition: boolean): boolean {
    return condition != null && condition;
  }
  //RECEPCIONAR TRAMITE
  buscarTramite(tramite: any): Observable<any> {
    return this.baseHttpService.post('tramite/buscar', tramite);
  }

  recepcionarTramiteFD(idTramite: number): Observable<any> {
    return this.baseHttpService.post('tramite/recepcionar/pdf-fd', { idTramite: idTramite });
  }

  guardarDocumentoFD(documentoFD: DocumentoFD): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-fd", documentoFD);
  }

  guardarTramiteFD(tramite: any): Observable<any> {
    return this.baseHttpService.post('tramite/guardar-fdf', tramite);
  }
  aprobarRectificacion(tramite: any): Observable<any> {
    return this.baseHttpService.post("tramite/aprobar-solicitud-rectificacion", tramite);
  }
  rechazarRectificacion(tramite: any): Observable<any> {
    return this.baseHttpService.post("tramite/rechazar-solicitud-rectificacion", tramite);
  }
  generarInformeDeAprobacionRechazoFuncionario(tramite: any): Observable<any> {
    console.log('TRAMITE', tramite);
    return this.baseHttpService.post("tramite/generar-informe-de-aprobacion-o-rechazo-de-rectificacion-funcionario", tramite);
  }

  guardarDocumentoFirmadoDigitalmente(tramite: any): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-documento-firmado-digitalmente", tramite);
  }

  guardarDocumentoFirmadoDigitalmenteFuncionario(tramite: any): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-documento-firmado-digitalmente-funcionario", tramite);
  }

  verSolicitudDeRectificacion(tramiteId: any): Observable<any> {
    return this.baseHttpService.get(`tramite/ver-solicitud-rectificacion/${tramiteId}`);
  }

  obtenerDocumentos(tramiteId: number): Observable<any> {
    return this.baseHttpService.get(`tramite/documentos/${tramiteId}`);
  }

  tamanioDocumentos(tramiteId: number): Observable<any> {
    return this.baseHttpService.get(`tramite/documentos/${tramiteId}`);
  }

  obtenerEstadosTipoTramite(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/obtener-estados-tipo-tramite', datos);
  }

  preRechazoTramite(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/improcedencia-tramite', datos);
  }

  paraInspeccion(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/tramite-para-inspeccion', datos);
  }

  paraInspeccionActualizacion(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/tramite-actualizacion-para-inspeccion', datos);
  }

  preRechazoTramiteRenovacion(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/improcedencia-tramite-renovacion', datos);
  }
  
  observacionTramiteRenovacion(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/improcedencia-tramite-renovacion-insp', datos);
  }

  preRechazoTramiteAprobado(datos: any): Observable<any> {
    return this.baseHttpService.post('tramite/improcedencia-tramite-aprobado', datos);
  }

  ///////formulario rechazo
  public rechazoTramite(data: any): Observable<any> {
    return this.baseHttpService.post(`tramite/rechazo-tramite`, data)
  }
  public rechazoTramiteAprobado(data: any): Observable<any> {
    return this.baseHttpService.post(`tramite/rechazo-tramite-aprobado`, data)
  }
  public actualizarEstadoAprobadoRechazo(data: any): Observable<any> {
    return this.baseHttpService.post(`tramite/actualizar-estado-aprobado-rechazo`, data)
  }
  public crearRechazo(data: any): Observable<any> {
    return this.baseHttpService.post(`tramite/crear`, data)
  }
  public envioCorreoImprocedencia(id_tramite: string | number): Observable<any> {
    return this.baseHttpService.get(`tramite/envioCorreoImprocedencia/` + id_tramite);
  }
  public obtenerDatosTramite(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/rechazo-obtener-datos/`, datos);
  }

  generarCPT(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/cpt", datos);
  }

  pdfCD(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/documento-adicional/pdf-cd", datos);
  }

  pdfFD(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/documento-adicional/pdf-fd", datos);
  }

  obtenerTramite(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/obtener-tramite", datos)
  }

  guardarTramiteRechazo(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-tramite-rechazo", datos)
  }
  // Observaciones de tramites administrado realizado por le Técnico de Operaciones
  public guardarObservacionAdministrado(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-administrado`, datos);
  }
  // Observacion Informe Técnico realizado por el Responsable de Operaciones
  public guardarObservacionInformeTecnico(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-informe-tecnico`, datos);
  }
  // Observacion Informe Tecnico aprobado por el Responsable de Operaciones realizado por le Director General
  public guardarObservacionInformeTecnicoRO(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-informe-tecnico-ro`, datos);
  }
  // Observacion Informe Tecnico aprobado por el Director General realizado por el Técnico Juridico VDS
  public guardarObservacionInformeTecnicoDG(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-informe-tecnico-dg`, datos);
  }
  // Observacion Informe Legal realizado por el Responsable Juridico VDS
  public guardarObservacionInformeLegal(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-informe-legal`, datos);
  }
  // Observacion Informe Legal aprbado por le Responsable Juridico VDS realizado por el Viceministro
  public guardarObservacionInformeLegalUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-observar-informe-legal-uj`, datos);
  }
  // Informe Técnico de Improcedencia de Técnico Operaciones
  public guardarInformeTecnicoImprocedencia(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia`, datos);
  }
  // Aprobacion Informe Técnico de Improcedencia Responsable Operaciones
  public guardarInformeTecnicoImprocedenciaAprobadoRO(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-aprobado-ro`, datos);
  }
  // Aprobacion Informe Técnico de Improcedencia Director General
  public guardarInformeTecnicoImprocedenciaAprobadoDG(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-aprobado-dg`, datos);
  }
  // Aprobacion Informe Técnico de Improcedencia Unidad Jurídica
  public guardarInformeTecnicoImprocedenciaAprobadoUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-aprobado-uj`, datos);
  }
  // Aprobacion Informe Técnico de Improcedencia Viceministro
  public guardarInformeTecnicoImprocedenciaAprobadoVice(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-aprobado-vice`, datos);
  }
  // Observacion del Informe Técnico de Improcedencia Responsable Operaciones
  public guardarInformeTecnicoImprocedenciaObservadoRO(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-observado-ro`, datos);
  }
  // Observacion del Informe Técnico de Improcedencia Director General
  public guardarInformeTecnicoImprocedenciaObservadoDG(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-observado-dg`, datos);
  }
  // Observacion del Informe Técnico de Improcedencia Unidad Jurídica
  public guardarInformeTecnicoImprocedenciaObservadoUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-observado-uj`, datos);
  }
  // Observacion del Informe Técnico de Improcedencia Viceministro
  public guardarInformeTecnicoImprocedenciaObservadoVice(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-it-improcedencia-observado-vice`, datos);
  }
  // Informe Legal de Improcedencia Técnico Legal
  public guardarInformeLegalImprocedencia(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-il-improcedencia`, datos);
  }
  // Aprobacion Informe Legal de Improcedencia Unidad Jurídica
  public guardarInformeLegalImprocedenciaAprobadoUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-il-improcedencia-aprobado-uj`, datos);
  }
  // Aprobacion Informe Legal de Improcedencia Viceministro
  public guardarInformeLegalImprocedenciaAprobadoVice(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-il-improcedencia-aprobado-vice`, datos);
  }
  // Observacion Informe Legal de Improcedencia Unidad Jurídica
  public guardarInformeLegalImprocedenciaObservadoUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-il-improcedencia-observado-uj`, datos);
  }
  // Observacion Informe Legal de Improcedencia Viceministro
  public guardarInformeLegalImprocedenciaObservadoVice(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-il-improcedencia-observado-vice`, datos);
  }
  // Informe Técnico de Improcedencia de Técnico Operaciones
  public guardarInformeImprocedencia(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-informe-improcedencia`, datos);
  }
  // Informe Técnico de Improcedencia de Técnico Operaciones
  public guardarInformeImprocedenciaUJ(datos: any): Observable<any> {
    return this.baseHttpService.post(`tramite/ap-informe-improcedencia-uj`, datos);
  }

}
